import './text-media.scss';

class TextMedia {
    constructor (element) {
        this.$textMedia = element;
    }

    initialize () {
        //
    }
}

export { TextMedia };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $textMedia = $context.querySelectorAll('[data-text-media="root"]');

        for (let i = 0; i < $textMedia.length; i++) {
            const $$textMedia = new TextMedia($textMedia[i]);
            $$textMedia.initialize();
        }
    }
});
